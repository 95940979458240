import DOMPurify from "dompurify";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import ProductMobile from "../ProductMobile/ProductMobile";
import DetailProductMobile from "../DetailProductMobile/DetailProductMobile";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { CoverEnum } from "../../../../../shared/infrastructure/CoverEnum";
import { Translate as T } from "react-localize-redux";
import TimerCover from "../../../TimerCover/TimerCover";
import { useHistory, useParams } from "react-router-dom";
import { ButtonStyleEnum } from "../../../../../shared/infrastructure/ButtonStyleEnum";
import { IIndexPathParameter } from "../../../../../shared/infrastructure/IIndexPathParameter";
import { StructureEnum } from "../../../../../shared/infrastructure/StructureEnum";
import theme from "../../../../../theme";

const useStyle = makeStyles((theme: Theme) => ({
  buttonStyleRound: {
    background: "#023365",
    borderRadius: 50,
    height: "48px",
    marginTop: theme.spacing(2),
  },
  buttonStyleSemi: {
    background: "#023365",
    borderRadius: 4,
    height: "48px",
    marginTop: theme.spacing(2),
  },
  buttonStyleSquare: {
    background: "#023365",
    borderRadius: 0,
    height: "48px",
    marginTop: theme.spacing(2),
  },
  cardHeadPaddingStyle: {
    paddingLeft: theme.spacing(1),
  },
  cardMarginStyle: {
    margin: theme.spacing(2),
    position: "relative",
  },
  componentMarginStyle: {
    marginTop: theme.spacing(3),
  },
  descriptionStyle: {
    ...theme.typography.subtitle1,
    color: "#293036",
    fontSize: "14px",
    fontWeight: "lighter",
    lineHeight: "140%",
  },
  topHeader: {
    padding: "2px 5px",
    position: "absolute",
  },
  totalTextStyle: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    ...theme.typography.subtitle1,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontWeight: "bold",
    height: "16px",
  }),
  totalValueStyle: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    ...theme.typography.subtitle1,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "140%",
    marginTop: "4px",
  }),
}));

const CardCoverMobile: React.FC = () => {
  const { generalConfig, paymentConfig, styleAndStructure } = useSelector(
    (state: IAppState) => state.smartlink!
  );
  const colors = useSelector((state: IAppState) => {
    return state.merchantCustomizationInfo?.colors || {};
  });
  const { primary: primaryColor } = colors;
  const styles = useStyle({ primaryColor });
  const totalAmount = useSelector((state: IAppState) => state.amount!.total);
  const { description, buyButtonText } = generalConfig;
  const {
    amount: { currency },
  } = paymentConfig;
  const { coverModel, buttonStyle } = styleAndStructure;
  const params = useParams<IIndexPathParameter>();
  const { merchantName, smartlinkId } = params;
  const history = useHistory();
  const routeChange = () => {
    history.push(
      `/${merchantName || "smartlink"}/${smartlinkId}/${StructureEnum.CHECKOUT}`
    );
  };

  const sanitizedHtml = DOMPurify.sanitize(description);

  return (
    <>
      <Card className={styles.cardMarginStyle}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              {coverModel === CoverEnum.LEFT ? (
                <Grid container>
                  <Grid item xs={4}>
                    <ProductMobile />
                  </Grid>
                  <Grid item xs={8} className={styles.cardHeadPaddingStyle}>
                    <DetailProductMobile />
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={8}>
                    <DetailProductMobile />
                  </Grid>
                  <Grid item xs={4} className={styles.cardHeadPaddingStyle}>
                    <ProductMobile />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={styles.componentMarginStyle}>
              <Typography variant="h6" className={styles.totalTextStyle}>
                <T id="total" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={styles.totalValueStyle}>
                {Intl.NumberFormat("de-DE", {
                  minimumFractionDigits: 0,
                }).format(totalAmount)}{" "}
                {currency}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={
                  buttonStyle === ButtonStyleEnum.SEMI
                    ? styles.buttonStyleSemi
                    : buttonStyle === ButtonStyleEnum.ROUND
                    ? styles.buttonStyleRound
                    : styles.buttonStyleSquare
                }
                style={{
                  background: primaryColor
                    ? primaryColor
                    : theme.palette.primary.dark,
                  color: "#ffffff",
                }}
                onClick={routeChange}
              >
                {buyButtonText}
              </Button>
            </Grid>
            {generalConfig.expirationDate && generalConfig.showTimer && (
              <Grid item xs={12}>
                <Box mt="24px">
                  <TimerCover />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} className={styles.componentMarginStyle}>
              <Typography className={styles.descriptionStyle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHtml,
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CardCoverMobile;
