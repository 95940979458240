import React, { PropsWithChildren } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Translate as T } from "react-localize-redux";
import theme from "../../../theme";
import { PaymentTypeEnum } from "../../../shared/infrastructure/PaymentTypeEnum";
import { useSmartlinkDetailTotal } from "./useSmartlinkDetailTotal/useSmartlinkDetailTotal";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";

const useStyles = makeStyles(() => ({
  toggleButtonGroupRoot: {
    borderRadius: "5px !important",
    paddingTop: "16px",
  },
  toggleButtonRoot: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    "& .MuiTypography-subtitle1": {
      color: primaryColor ? primaryColor : theme.palette.text.primary,
    },
    "&.Mui-selected": {
      backgroundColor: primaryColor ? primaryColor : theme.palette.primary.dark,
    },
    "&.Mui-selected .MuiTypography-subtitle1": {
      color: "#ffffff",
    },
    "&.Mui-selected:hover": {
      backgroundColor: primaryColor ? primaryColor : theme.palette.primary.dark,
    },
    background: theme.palette.background.paper,
    border: "0.6px solid",
    borderColor: primaryColor ? primaryColor : theme.palette.primary.dark,
    height: "32px",
    textTransform: "none",
  }),
  totalText: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "20px",
    fontWeight: "bold",
  }),
  widthMenu: {
    "& div": {
      width: "30%",
    },
    "& ul": {
      padding: 0,
    },
  },
}));

export interface ITotalProps {
  handlerIsOpen: () => void;
  isOpen: boolean;
}

const SmartlinkDetailTotal: React.FC<ITotalProps> = (
  props: React.PropsWithChildren<ITotalProps>
) => {
  const {
    paymentType,
    amountData,
    handler: { onChange },
    paymentTypeSmarlink,
  } = useSmartlinkDetailTotal();
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const style = useStyles({ primaryColor });

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        {paymentTypeSmarlink === PaymentTypeEnum.MIXED && (
          <ToggleButtonGroup
            defaultValue={PaymentTypeEnum.UNIQUE}
            value={paymentType}
            exclusive
            onChange={onChange}
            aria-label="text alignment"
            classes={{ root: style.toggleButtonGroupRoot }}
          >
            <ToggleButton
              value={PaymentTypeEnum.UNIQUE}
              classes={{ root: style.toggleButtonRoot }}
            >
              <Typography variant={"subtitle1"}>
                <T id="singlePayment" />
              </Typography>
            </ToggleButton>
            <ToggleButton
              value={PaymentTypeEnum.SUBSCRIPTION}
              classes={{ root: style.toggleButtonRoot }}
            >
              <Typography variant={"subtitle1"}>
                <T id="subscription" />
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {paymentTypeSmarlink === PaymentTypeEnum.UNIQUE && (
          <Typography variant={"h5"}>
            <T id="singlePayment" />
          </Typography>
        )}
        {paymentTypeSmarlink === PaymentTypeEnum.SUBSCRIPTION && (
          <Typography variant={"h5"}>
            <T id="subscription" />
          </Typography>
        )}
      </Grid>
      <Box mb={2} />
      <Grid container item xs={12} md={12} lg={12}>
        <Grid container item xs={12} md={12} lg={12}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant={"h6"}>
              <T id="taxes" />
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant={"h6"} align={"right"}>
              {amountData.taxes} {amountData.currency}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} lg={12}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant={"h6"}>
              <T id="subtotal" />
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant={"h6"} align={"right"}>
              {amountData.subTotal} {amountData.currency}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box
        display={"flex"}
        justifyContent={"center"}
        onClick={() => props.handlerIsOpen()}
      >
        <Grid container item xs={12} md={12} lg={12}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant={"subtitle1"} className={style.totalText}>
              <T id="total" />
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography
              variant={"subtitle1"}
              align={"right"}
              className={style.totalText}
            >
              {amountData.total.toFixed(2)} {amountData.currency}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default SmartlinkDetailTotal;
