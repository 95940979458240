import React, { useEffect } from "react";
import { Box, Container, Grid, makeStyles, Theme } from "@material-ui/core";
import { SmartLink } from "../../../types/smart_link_V2";
import TopHeader from "../../components/commons/TopHeader/TopHeader";
import { useStateApp } from "../../state/useStateApp";
import SmartlinkDetailMobile from "../../components/Payment/SmartlinkDetailMobile/SmartlinkDetailMobile";
import SmartlinkDetail from "../../components/Payment/SmartlinkDetail/SmartlinkDetail";
import StepperPayment from "../../components/Payment/StepperPayment/StepperPayment";
import { useStepperPaymentContext } from "../../components/Payment/StepperPayment/context/useStepperPaymentContext";
import { useHistory } from "react-router-dom";
import { ResumeTypeEnum } from "../../shared/infrastructure/ResumeTypeEnum";
import { ResumeResponse } from "../../../types/resume_response";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { PaymentMethodsEnum } from "../../shared/infrastructure/PaymentMethodEnum";
import PreviewBanner from "../../components/commons/PreviewBanner/PreviewBanner";

const useStyles = makeStyles((theme: Theme) => ({
  boxPayments: {
    marginLeft: 20,
  },
  cardActionDesktop: {
    marginLeft: "auto",
    width: "50%",
  },
  cardActionMobile: {
    width: "100%",
  },
  container: {
    paddingBottom: 60,
  },
  previewBannerContainer: {
    bottom: "50px",
    position: "sticky",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      bottom: "50px",
    },
  },
}));

export interface ILocationState {
  resumeType: ResumeTypeEnum;
  resumeData: ResumeResponse;
  smartlink: SmartLink;
  paymentMethod: PaymentMethodsEnum;
  formData: {
    name: string;
    identification: string;
    email: string;
  };
}

const Payment: React.FC = () => {
  const { isMobile } = useStateApp();
  const smartlink = useSelector((state: IAppState) => state.smartlink!);
  const renderComponent = !!smartlink;
  const { getContent, step, showStepper, renderCheckout } =
    useStepperPaymentContext();
  const classes = useStyles();
  const draftMode: boolean = useSelector((state: IAppState) => state.draftMode);
  const history = useHistory();

  useEffect(() => {
    if (!renderComponent) return;
  }, [renderComponent]);

  const paymentComponent = showStepper ? (
    <>
      <Grid item container alignItems="center" style={{ minHeight: "60px" }}>
        <StepperPayment />
      </Grid>
      <Grid item className={classes.boxPayments}>
        {getContent(step)}
      </Grid>
    </>
  ) : (
    <Grid item className={classes.boxPayments}>
      {renderCheckout("block")}
    </Grid>
  );

  return (
    <>
      {renderComponent ? (
        <>
          <Container maxWidth={"lg"} className={classes.container}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TopHeader />
              </Grid>
              {isMobile ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      marginBottom: draftMode && isMobile ? "90px" : "0px",
                    }}
                  >
                    {showStepper ? (
                      <>
                        <StepperPayment />
                        <SmartlinkDetailMobile />
                        <Box pt={3}>{getContent(step)}</Box>
                      </>
                    ) : (
                      <>
                        <SmartlinkDetailMobile />
                        {renderCheckout("block")}
                      </>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} sm={5}>
                    <SmartlinkDetail />
                  </Grid>
                  <Grid item container direction="column" xs={12} sm={7}>
                    {paymentComponent}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Container>
          {draftMode && (
            <Box className={classes.previewBannerContainer}>
              <PreviewBanner />
            </Box>
          )}
        </>
      ) : (
        history.push("./")
      )}
    </>
  );
};

export default Payment;
