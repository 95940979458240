export enum ActionTypes {
  SET_LOADING = "[MAIN] SET_LOADING",
  SET_SMARTLINK = "[MAIN] SET_SMARTLINK",
  SET_SOCIAL_REASON = "[MAIN] SET_SOCIAL_REASON",
  SET_DRAFT_MODE = "[MAIN] SET_DRAFT_MODE",
  SET_PAYMENT_METHODS = "[MAIN] SET_PAYMENT_METHODS",
  SET_CHECKOUT_PAYMENT_TYPE = "[MAIN] SET_CHECKOUT_PAYMENT_TYPE",
  SET_AMOUNT = "[MAIN] SET_AMOUNT",
  SET_TERMS_AND_CONDITIONS = "[MAIN] SET_TERMS_AND_CONDITIONS",
  SET_SMARTLINK_STRUCTURE = "[MAIN] SET_SMARTLINK_STRUCTURE",
  SET_MERCHANT_ID = "[MAIN] SET_MERCHANT_ID",
  SET_SMARTLINK_ID = "[MAIN] SET_SMARTLINK_ID",
  SET_MERCHANT_CUSTOMIZATION_INFO = "[MAIN] SET_MERCHANT_CUSTOMIZATION_INFO",
  SET_SIFTSCIENCE_DETAILS = "[MAIN] SET_SIFTSCIENCE_DETAILS",
  SET_DYNAMIC_DETAILS = "[MAIN] SET_DYNAMIC_DETAILS",
  SET_INFO_FIREBASE = "[MAIN] SET_INFO_FIREBASE",
}
