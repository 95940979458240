import { IAppTranslation } from "../service/MultiLanguage";

export const portuguese: IAppTranslation = {
  agree: ["Aceite os "],
  btnLabels: {
    buttonFormLabel: ["Vai pagar"],
    payCheckoutDesktop: ["Iniciar o pagamento"],
    payCheckoutMobile: ["Ir para o pagamento"],
    retry: ["Tente novamente"],
  },
  cardAsyncSection: {
    email: ["endereço de correio electrónico"],
  },
  cardCheckout: {
    cardNumber: ["Número de cartão"],
    CVC: ["CVC"],
    expirationDate: ["MM/AA"],
    name: ["Nome"],
    nameLastname: ["Nomes e sobrenomes"],
  },
  cardSection: {
    buttonText: ["Mudar o método de pagamento"],
  },
  cardSubscriptionAsync: {
    email: ["Email"],
  },
  cashSection: {
    email: ["E-mail"],
    fieldRequired: ["* Este campo é obrigatório"],
    identificationNumber: ["Número de identificação"],
    invalidEmail: ["E-mail inválido"],
    lastName: ["Apellidos"],
    name: ["Nombre"],
    payButton: ["Gerar ordem de pagamento"],
    paymentOrder: ["Seu pedido de pagamento será gerado por"],
  },
  confirmationData: {
    cancelButton: ["Cancelar"],
    subscribeButton: ["Se inscrever"],
    subtitle: [
      "Por razões de segurança, precisamos validar alguns dados adicionais, mas não se preocupe ",
    ],
    subtitleBold: ["faremos isso apenas uma vez."],
    title: ["¡um momento!"],
  },
  confrontaAuthentication: {
    CancelButton: ["Cancelar"],
    cityPlaceholder: ["Cidade"],
    confrontaLabel: [
      "Preencha as seguintes informações, relacionadas ao documento de identidade:",
    ],
    expeditionDatePlaceholder: ["Data de expedição"],
    howItWorks: ["¿Como funciona?"],
    identificationLabel: [],
    provinceLPlaceholder: ["Departamento"],
    requiredField: ["Campo obrigatório"],
    subscribeButton: ["Se inscrever "],
    validateData: ["Verificação de dados"],
  },
  deferredOptions: {
    months: ["Meses"],
    monthsOfGrace: ["Meses de graça"],
    title: ["Quero pagar parcelado"],
    typeDeferred: ["Tipo diferido"],
  },
  emptyInfo: ["Você não tem dados de pagamento salvos"],
  emptyInfoButton: ["Tente outro método de pagamento"],
  expiredLink: {
    description: [
      "Caso necessite adquirir este serviço ou produto, contacte-nos.",
    ],
    title: ["Desculpe, este smartlink expirou"],
  },
  footer: {
    contacts: ["Entre em contato conosco."],
    help: ["¿Precisa de ajuda?,"],
  },
  form: {
    additionalData: ["Dados adicionais"],
    cvcInvalid: ["* CVC inválido"],
    emailInvalid: ["* Email inválido"],
    expirationDateInvalid: ["* Data inválida"],
    fieldRequired: ["* Este campo é obrigatório"],
    fillData: ["Preencha seus dados"],
    numberCardInvalid: ["* Número do cartão inválido"],
    optionalField: ["(Opcional)"],
    personalData: ["Dados pessoais"],
    selectEmptyOption: ["Selecionar..."],
    title: ["Forma"],
  },
  headerText: {
    cardOrDebit: ["Cartão de crédito ou débito"],
    changePayment: ["Cartão de crédito ou débito"],
    paymentMethods: ["Métodos de pagamento disponíveis"],
  },
  howtoWorksButton: ["¿Como funciona?"],
  informationConfronta: [
    "Após a validação dos seus dados de identidade, iremos apresentar-lhe várias questões sobre os seus dados pessoais e / ou bancários que deverá responder corretamente para efetuar a transação.",
  ],
  itemDescriptions: {
    makePaymentsCard: [
      "Efetue pagamentos com o seu cartão Visa, Master ou Amex",
    ],
    makePaymentsCardAsync: ["Faça pagamentos com seu cartão"],
    makePaymentsCardDynamic: [
      "Efetue pagamentos com seu cartão de crédito ou débito",
    ],
    makePaymentsCardSubscription: ["Inscreva-se em um plano de pagamento"],
    makePaymentsCardSubscriptionAsync: ["Inscreva-se com o webpay"],
    makePaymentsCardSubscriptionDynamic: [
      "Inscreva-se em um plano de pagamento",
    ],
    makePaymentsCash: ["Pague nos pontos de atendimento mais próximos de você"],
    makePaymentsKpay: [
      "Faça pagamentos rápidos com os dados armazenados pelo K Pay",
    ],
    makePaymentsTransfer: ["Efetue pagamentos com o banco de sua escolha"],
  },
  itemTitles: {
    cardAsync: ["Cartão de débito"],
    cardDynamic: ["Cartão de crédito ou débito"],
    cardSubscription: ["Assinatura de cartão de crédito"],
    cardSubscriptionAsync: ["Assinatura de cartão"],
    cardSubscriptionDynamic: ["Assinatura de cartão de crédito"],
    cash: ["Dinheiro"],
    checkData: ["Verificação de dados"],
    creditCard: ["Cartão de crédito"],
    fastPay: ["Pagamento rápido"],
    transfer: ["Transferir"],
    transferSubscription: ["Débito recorrente"],
  },
  kpayEmail: {
    cancel: ["Cancelar"],
    confirm: ["Confirme"],
    edit: ["Editar"],
    invalidEmail: ["E-mail inválido"],
    placeholder: ["Email"],
    return: ["Para retornar"],
    subtittle: ["Insira seu e-mail para uma melhor experiência de pagamento."],
    tittle: ["Você salvou dados?"],
  },
  kpayOtp: {
    backButtonHelper: ["Retornar"],
    changePaymentMethod: ["Use outra forma de pagamento"],
    codePlaceholderHelper: ["Código de verificação"],
    confirmButtonHelper: ["Confirme o código"],
    emailCodeHelper: [
      "Enviamos um código de verificação para o seu e-mail para confirmar a compra.",
    ],
    requiredField: ["Campo obrigatório"],
    safetyHelper: ["Nos preocupamos com a segurança de seus dados."],
    wrongCodeError: ["O código inserido está incorreto, tente novament"],
  },
  kpayResume: {
    info: ["Dados salvos para compras futuras com"],
  },
  languages: {
    br: ["Português"],
    en: ["Inglês"],
    es: ["Espanhol"],
  },
  languagesShort: {
    br: ["PR"],
    en: ["EN"],
    es: ["ES"],
  },
  listPaymentMethods: {
    expiredCardMessage: ["O cartão expirou"],
    titleCard: ["Cartão de débito "],
    titleCardAndDebit: ["Cartão de débito ou de crédito"],
    titleCash: ["Dinheiro"],
    titleOtherPay: ["Outros meios de pagamento "],
    titlePayMethods: ["Meios de pagamento"],
    titlePaySaved: ["Detalhes de pagamento salvos"],
    titleTransfer: ["Transferência de dinheiro"],
  },
  loadingLabel: ["Carregando"],
  loadingOverlay: {
    mainLine: ["Espere um momento"],
    secondaryLine: ["Estamos processando seu pagamento"],
  },
  notAvail: {
    description: [
      "Se você precisa comprar este produto ou serviço, entre em contato conosco.",
    ],
    title: ["Desculpe, este produto não está disponível"],
  },
  notFound: {
    description: [
      "Entre em contato conosco para compartilhar o link que você precisa para pagar",
    ],
    title: ["Lo sentimos, tem usado um enlace incorrecto"],
  },
  notification: {
    confirmIdentity: [
      "Não foi possível confirmar sua identidade, tente novamente",
    ],
  },
  otpInvalidTries: {
    partFourText: ["Tente outro cartão"],
    partOneText: ["Houve muitas tentativas malsucedidas"],
    partTreeText: [
      "*Lembre-se que o valor que foi carregado ou debitado do seu cartão será estornado imediatamente",
    ],
    partTwoText: [
      "A verificação não pôde ser concluída porque você falhou em muitas tentativas. Volta a tentar",
    ],
  },
  otpTextVerification: {
    confirmCode: ["Confirme o código"],
    invalidCode: ["Código inválido"],
    partOneText: ["Fizemos uma pequena cobrança no seu cartão como "],
    partTwoText: [
      ", que será revertida imediatamente. A cobrança efetuada tem três (3) digitos, os quais deverá colocar a seguir:",
    ],
    verification: ["Verificação"],
  },
  outStock: {
    description: [
      "Se você precisa comprar este produto ou serviço, entre em contato conosco",
    ],
    title: ["Desculpe, estamos fora de estoque"],
  },
  payButton: ["Pagar "],
  pci: [
    "Este pagamento é processado com segurança por Kushki, um provedor de pagamento certificado PCI Nível 1.",
  ],
  previewBanner: [
    "Esta é uma prévia do smartlink que você criou, se precisar publicá-lo, volte para a guia de criação e clique em  ",
  ],
  pse: {
    typePerson: ["Tipo de pessoa"],
  },
  readMore: ["Ler mais"],
  recurrentDebit: {
    current: ["Corrente"],
    savings: ["Poupança"],
    typeAccount: ["Tipo de conta"],
  },
  saveKpayLabel: ["Economize dados para compras futuras com"],
  saveText: ["Guardar"],
  siftScienceForm: {
    address: ["Endereço principal"],
    city: ["Cidade"],
    country: ["País"],
    countryCode: ["Código do país"],
    department: ["Departamento"],
    description: ["Insira seus detalhes"],
    documentNumber: ["Número do documento"],
    documentType: ["Tipo de documento"],
    email: ["Correio eletrônico"],
    firstName: ["Nomes"],
    lastName: ["Sobrenomes"],
    municipality: ["Município"],
    personalData: ["Dados pessoais"],
    phone: ["Número de telefone"],
    province: ["Província"],
    region: ["Estado/Província/Região"],
    required: ["Obrigatório"],
    secondaryAddress: ["Endereço secundário (Opcional)"],
    title: ["Forma"],
    zipCode: ["Código postal"],
  },
  singlePayment: ["Pagamento Único"],
  spei: {
    description: [
      "Insira seu e-mail e clique em “Gerar ordem de pagamento” para receber os dados necessários para efetuar sua transferência",
    ],
    message: ["Seu pedido de pagamento será gerado por "],
  },
  stepperLabels: {
    FORM: ["Dados"],
    PAY: ["Pago"],
  },
  subscription: ["Inscrição"],
  subscriptionButton: ["Subscribe"],
  subscriptionPeriodicity: {
    bimonthly: ["Mês e meio"],
    biweekly: ["Quinzenalmente"],
    custom: ["Personalizado"],
    daily: ["Diariamente"],
    fourmonths: ["A cada quatro meses"],
    halfYearly: ["Semestral"],
    monthly: ["Mensalmente"],
    quarterly: ["Trimestralmente"],
    threefortnights: ["A cada dois meses"],
    weekly: ["Semanalmente"],
    yearly: ["Anual"],
  },
  subtotal: ["Subtotal"],
  sumarizeLabelsForTransferETPAY: {
    amount: ["RESULTAR"],
    date: ["DATA"],
    email: ["O EMAIL"],
    name: ["NOME"],
    nit: ["NIT"],
    personalInfo: ["DADOS PESSOAIS"],
    product: ["PRODUTO"],
    status: ["STATUS"],
    successSubTitle: ["Sua compra foi bem sucedida"],
    successTitle: ["Excelente"],
    summarizeTitle: ["Resumo da compra"],
    ticketNumber: ["TICKET NUMBER"],
  },
  sumarizeLabelsForTransferPSE: {
    amount: ["RESULTAR"],
    bank: ["BANCO"],
    cus: ["CUS"],
    date: ["DATA"],
    email: ["O EMAIL"],
    identification: ["IDENTIFICAÇÃO"],
    name: ["NOME"],
    personalInfo: ["DADOS PESSOAIS"],
    product: ["PRODUTO"],
    socialReason: ["RAZÃO SOCIAL"],
    successSubTitle: ["Sua compra foi bem sucedida"],
    successTitle: ["Excelente"],
    summarizeTitle: ["NÚMERO DE TICKET"],
  },
  summarizeAccendo: {
    amount: ["RESULTAR"],
    bank: ["BANCO DE DESTINO"],
    beneficiaryName: ["NOME DO BENEFICIÁRIO"],
    clabeAccount: ["Conta Clabe"],
    personalData: ["DADOS PESSOAIS"],
    product: ["PRODUTO"],
    referenceNumber: ["No. DE REFERÊNCIA"],
    speiInfo: ["Informação para SPEI"],
    successSubTitleBold: ["Conta CLABE, número de referência e valor"],
    successSubTitlePart1: [
      "Para efetuar o seu pagamento, entre no seu banco eletrônico e faça um SPEI com o número da ",
    ],
    successSubTitlePart2: [" que mostramos abaixo:"],
    summarizeTitle: ["Compra aguardando pagamento"],
  },
  summarizeBankBogota: {
    successTitle: ["Excelente"],
    summarizeAmountLabel: ["RESULTAR"],
    summarizePersonalInfoLabel: ["DADOS PESSOAIS"],
    summarizeProductLabel: ["PRODUTOS"],
    summarizeStateLabel: ["DOENÇA"],
    summarizeTitle: ["Resumo da compra"],
  },
  summarizeLabels: {
    successSubTitle: ["Sua compra foi bem sucedida"],
    successTitle: ["Excelente"],
    summarizeAmountLabel: ["RESULTAR"],
    summarizePersonalInfoLabel: ["DADOS PESSOAIS"],
    summarizeProductLabel: ["PRODUTOS"],
    summarizeStateLabel: ["DOENÇA"],
    summarizeTicketNumberLabel: ["NÚMERO DE TICKET"],
    summarizeTitle: ["Resumo da compra"],
  },
  summarizeLabelsForCash: {
    downloadButtonLabel: ["Baixar ordem de pagamento"],
    paymentCash: ["pagamento em dinheiro"],
    rememberMsm: [
      "Lembre-se: a sua compra só será confirmada depois de efetuar o pagamento com o PIN que enviamos para o seu e-mail",
    ],
    successSubTitle: ["Geramos seu PIN de pagamento"],
    summarizePayMethodLabel: ["FORMA DE PAGAMENTO"],
  },
  summarizeLabelsForSubscription: {
    paymentSubscription: ["Inscrição"],
    payMethodLabel: ["FORMA DE PAGAMENTO"],
    periodicityLabel: ["PERIODICIDADE"],
    subscriptionIDLabel: ["ID DE INSCRIÇÃO"],
    successSubTitle: ["Sua inscrição foi bem sucedida"],
  },
  summarizeLabelsForTransferSPEI: {
    amount: ["RESULTAR"],
    clabeAccount: ["Conta Clabe"],
    email: ["O EMAIL"],
    paymentMethod: ["FORMA DE PAGAMENTO"],
    personalData: ["DADOS PESSOAIS"],
    product: ["PRODUTO"],
    referenceNumber: ["NÚMERO DE REFERÊNCIA"],
    speiInfo: ["Informação para SPEI"],
    successSubTitleBold: ["conta CLABE, número de referência e valor"],
    successSubTitlePart1: [
      "Para efetuar o seu pagamento, entre no seu banco eletrônico e faça um SPEI com o número da ",
    ],
    successSubTitlePart2: [" que mostramos abaixo:"],
    summarizeTitle: ["Compra aguardando pagamento"],
    ticketNumber: ["NÚMERO DE TICKET"],
    transaction: ["SPEI - Transferência bancária"],
  },
  taxes: ["Impostos"],
  terms: ["Termos e Condições"],
  termsConditions: ["Debe aceptar los terminos y condiciones"],
  timerCover: {
    days: ["dias"],
    description: ["Esta oferta expira em"],
    hours: ["horas"],
    minutes: ["minutos"],
    seconds: ["segundos"],
  },
  total: ["Total"],
  transactionFailed: {
    description: ["A transação não pôde ser concluída. Tente novamente."],
    title: ["Algo deu errado"],
  },
  transactionStatus: {
    approval: ["Aprovado"],
    initialized: ["Inicializado"],
  },
  transferSafetyPaySection: {
    documentNumber: ["Número de identificação"],
    email: ["E-mail"],
    fieldRequired: ["* Este campo é obrigatório"],
    invalidEmail: ["E-mail inválido"],
    name: ["Nomes e sobrenomes"],
    payButton: ["Pagar"],
  },
  transferSubscriptionCheckout: {
    accountNumber: ["Numero de conta"],
    accountType: ["Tipo de conta"],
    bankId: ["Escolha o seu banco"],
    email: ["Correio eletrônico"],
    howItWorks: ["Como funciona?"],
    identificationNumber: ["Número de identificação"],
    name: ["Nomes e sobrenomes"],
    phoneNumber: ["Número de telefone"],
    subscribeButton: ["Se inscrever "],
  },
  validateData: ["Verificação de dados"],
  verificationFailed: {
    description: ["Suas informações não puderam ser verificadas."],
    descriptionSecondary: ["O pagamento foi cancelado."],
    title: ["Falha na verificação de dados"],
  },
};
