import { Theme } from "@material-ui/core/styles";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import SiftForm from "../Payment/SiftScienceForm/SiftForm";
import { useFormContainerState } from "./useFormContainerState/useFormContainerState";
import { DynamicForm } from "../Payment/DynamicForm/DynamicForm";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    alignItems: "center",
    borderRadius: "4px",
    color: "white",
    fontSize: "16px",
    height: "40px",
    width: "313px",
  },
  card: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    height: "568px",
    width: "100%",
  },
  cardActions: {
    boxShadow:
      "0px 15px 0px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    height: "40px",
    paddingBottom: "12px",
    paddingRight: "32px",
    paddingTop: "12px",
  },
  contentCard: {
    height: "453px",
    marginLeft: "16px",
    marginRight: "16px",
    overflow: "auto",
    paddingTop: "35px",
  },
  descriptionText: {
    fontSize: "15px",
    fontWeight: 400,
  },
  detailCard: {
    borderRadius: "10px",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    padding: "16px",
  },
  formTitle: {
    align: "center",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  titleText: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    ...theme.typography.h5,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
  }),
}));

interface IFormContainerProps {
  display: string;
}

const FormContainer: ({ display }: IFormContainerProps) => JSX.Element = ({
  display,
}: IFormContainerProps) => {
  const {
    siftScienceEnabled,
    siftForm,
    handleContinueSubmit,
    dynamicForm,
    dynamicFormEnabled,
  } = useFormContainerState();
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const classes = useStyles({ primaryColor });

  return (
    <Grid item xs={12} md={12} lg={12} style={{ display: display }}>
      <Card className={classes.card}>
        <CardContent className={classes.contentCard}>
          {(siftScienceEnabled || dynamicFormEnabled) && (
            <>
              <Typography className={classes.titleText}>
                {<T id={"siftScienceForm.title"} />}
              </Typography>
              <Typography className={classes.descriptionText}>
                {<T id={"siftScienceForm.description"} />}
              </Typography>
              <br />
            </>
          )}
          {siftScienceEnabled && <SiftForm siftForm={siftForm} />}
          {dynamicFormEnabled && <DynamicForm form={dynamicForm} />}
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid item xs={12} sm={12}>
            <Box className={classes.box}>
              <Button
                variant="contained"
                color={"primary"}
                onClick={handleContinueSubmit}
                className={classes.button}
              >
                <T id={"btnLabels.buttonFormLabel"} />
              </Button>
            </Box>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default FormContainer;
