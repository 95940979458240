import { CheckOutResume } from "../../types/checkout_resume";
import { DynamicFormBody } from "../components/Payment/DynamicForm/DynamicForm";
import { databaseRef } from "../shared/FirebaseService";
import { PaymentMethodsEnum } from "../shared/infrastructure/PaymentMethodEnum";
import { IAppState, INITIAL_STATE } from "./reducer";
import { ActionTypes } from "./actionTypes";
import { CustomForm, SmartLink } from "../../types/smart_link_V2";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios, { AxiosResponse } from "axios";
import { environment } from "../environments/environment";
import { EndpointEnum } from "../shared/infrastructure/EndpointEnum";
import { defaultTo, get } from "lodash";
import {
  buildSmartlinkV1,
  getSessionStorageInfo,
  getTotalAmount,
  orderPaymentMethods,
  setSessionStorageInfo,
  validateSmartlink,
} from "../shared/utils/utils";
import { FormResponse } from "../../types/form_response";
import { IAmount } from "../shared/infrastructure/IAmount";
import { PaymentTypeEnum } from "../shared/infrastructure/PaymentTypeEnum";
import { KushkiJs } from "../shared/utils/kushki";
import { RouteEnum } from "../shared/infrastructure/RouteEnum";
import { Customization } from "../../types/merchant_fetch";
import * as H from "history";
import { SiftScienceDetails } from "../../types/sift_science_details";

export type IAppAction = { type: string } & Partial<IAppState>;

export const setLoading = (payload: boolean): IAppAction => {
  return {
    loading: payload,
    type: ActionTypes.SET_LOADING,
  };
};

export const setSmartlink = (payload: SmartLink): IAppAction => {
  return {
    smartlink: payload,
    type: ActionTypes.SET_SMARTLINK,
  };
};

export const setSocialReason = (payload: string): IAppAction => {
  return {
    socialReason: payload,
    type: ActionTypes.SET_SOCIAL_REASON,
  };
};

export const setDraftMode = (payload: boolean): IAppAction => {
  return {
    draftMode: payload,
    type: ActionTypes.SET_DRAFT_MODE,
  };
};

export const setPaymentMethods = (payload: string[]): IAppAction => {
  return {
    paymentMethods: payload,
    type: ActionTypes.SET_PAYMENT_METHODS,
  };
};

export const setCheckoutPaymentType = (
  payload: PaymentTypeEnum
): IAppAction => {
  return {
    checkoutPaymentType: payload,
    type: ActionTypes.SET_CHECKOUT_PAYMENT_TYPE,
  };
};

export const setAmount = (payload: IAmount): IAppAction => {
  return {
    amount: payload,
    type: ActionTypes.SET_AMOUNT,
  };
};

export const setTermsAndConditions = (payload: string): IAppAction => {
  return {
    termsAndConditions: payload,
    type: ActionTypes.SET_TERMS_AND_CONDITIONS,
  };
};

export const setSmartlinkStructure = (payload: string): IAppAction => {
  return {
    structure: payload,
    type: ActionTypes.SET_SMARTLINK_STRUCTURE,
  };
};

export const setMerchantId = (payload: string): IAppAction => {
  return {
    merchantId: payload,
    type: ActionTypes.SET_MERCHANT_ID,
  };
};

export const setSmartlinkId = (payload: string): IAppAction => {
  return {
    smartlinkId: payload,
    type: ActionTypes.SET_SMARTLINK_ID,
  };
};
export const setMerchantCustomizationInfo = (
  payload: Customization
): IAppAction => {
  return {
    merchantCustomizationInfo: payload,
    type: ActionTypes.SET_MERCHANT_CUSTOMIZATION_INFO,
  };
};

export const setSiftScienceDetails = (
  payload: SiftScienceDetails
): IAppAction => {
  return {
    siftScienceDetails: payload,
    type: ActionTypes.SET_SIFTSCIENCE_DETAILS,
  };
};

export const setDynamicDetails = (payload: DynamicFormBody): IAppAction => {
  const { merchantName, smartlinkId, formConfigString } =
    getSessionStorageInfo();

  setSessionStorageInfo({
    dynamicForm: JSON.stringify(payload),
    formConfigString: formConfigString,
    merchantName: merchantName,
    smartlinkId: smartlinkId,
  });

  return {
    dynamicDetails: payload,
    type: ActionTypes.SET_DYNAMIC_DETAILS,
  };
};

export const setInfoFirebase = (payload: CheckOutResume): IAppAction => {
  return {
    firebaseInfo: payload,
    type: ActionTypes.SET_INFO_FIREBASE,
  };
};

export const getSmartlink = (
  smartlinkId: string,
  history: H.History
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, void, IAppAction>
  ): Promise<void> => {
    dispatch(setLoading(true));

    try {
      const { data }: AxiosResponse<SmartLink | FormResponse> = await axios.get(
        `${environment.kushkiUrl}/${EndpointEnum.SMARTLINK_V2}/${smartlinkId}`
      );
      const isV2Smartlink =
        defaultTo(get(data, "version"), "v1").toLowerCase() === "v2";

      const smartlink: SmartLink = isV2Smartlink
        ? <SmartLink>data
        : buildSmartlinkV1(smartlinkId, <FormResponse>data);

      // TODO remove when siftscience enabled
      smartlink.siftScienceEnabled = false;

      const {
        paymentConfig: {
          paymentMethod,
          paymentType,
          subscriptionOptions,
          amount,
        },
        publicMerchantId,
        styleAndStructure: { structure },
        generalConfig,
      } = smartlink;

      generalConfig.executionCount = get(smartlink, "executionCount", 0);
      validateSmartlink(generalConfig, history);

      const paymentMethodsList: string[] = orderPaymentMethods(
        Array.isArray(paymentMethod) ? paymentMethod : [paymentMethod]
      );

      new KushkiJs(publicMerchantId!);

      dispatch(setSmartlinkId(smartlinkId));
      dispatch(setMerchantId(publicMerchantId!));
      dispatch(setSmartlinkStructure(structure));
      dispatch(setSmartlink(smartlink));
      dispatch(setDraftMode(get(smartlink, "draft", false)));

      if (paymentType === PaymentTypeEnum.SUBSCRIPTION) {
        dispatch(setTermsAndConditions(subscriptionOptions!.terms));
        dispatch(setAmount(getTotalAmount(subscriptionOptions!.amount)));
        dispatch(setCheckoutPaymentType(PaymentTypeEnum.SUBSCRIPTION));
        const methods = paymentMethodsList.filter(
          (method) =>
            method === PaymentMethodsEnum.SUBSCRIPTION ||
            method === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION ||
            method === PaymentMethodsEnum.CARD ||
            method === PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC ||
            method === PaymentMethodsEnum.CARD_DYNAMIC ||
            method === PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC
        );

        dispatch(setPaymentMethods(orderPaymentMethods(methods)));
      } else {
        const methods = paymentMethodsList.filter(
          (method) =>
            !(
              method === PaymentMethodsEnum.SUBSCRIPTION ||
              method === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION ||
              method === PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC ||
              method === PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC
            )
        );

        dispatch(setPaymentMethods(methods));
        dispatch(setTermsAndConditions(`${generalConfig.termsAndConditions}`));
        dispatch(setAmount(getTotalAmount(amount)));
        dispatch(setCheckoutPaymentType(PaymentTypeEnum.UNIQUE));
      }
      dispatch(setLoading(false));
      let formConfigString: string[] = [];

      smartlink.formConfig!.forEach((config: CustomForm) => {
        formConfigString.push(JSON.stringify(config));
      });

      setSessionStorageInfo({
        formConfigString: formConfigString.join(";"),
        merchantName: smartlink.merchantName,
        smartlinkId: smartlink.id!,
      });
    } catch (e) {
      history.push(RouteEnum.NOT_FOUND);
    }
  };
};

export const getMerchantCustomization = (
  publicMerchantId: string | undefined
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, undefined, IAppAction>,
    getState: () => IAppState
  ): Promise<void> => {
    dispatch(setLoading(true));

    const currentState: IAppState = getState();
    const defaultColors = { primary: "#00E6B2", secondary: "#023365" };

    try {
      const response = await axios.get<Customization>(
        `${environment.kushkiUrl}/merchant/v1/merchant/customization`,
        {
          headers: {
            ["Public-Merchant-Id"]: publicMerchantId,
          },
        }
      );

      const customization: Customization = response.data;

      if (
        get(customization, "colors.primary", "") === defaultColors.primary &&
        get(customization, "colors.secondary", "") === defaultColors.secondary
      ) {
        response.data.colors = {};
      }
      dispatch(
        setMerchantCustomizationInfo({
          ...INITIAL_STATE.merchantCustomizationInfo,
          ...customization,
        })
      );
    } catch (e) {
      const customization: Customization = {
        colors: {
          primary: get(
            currentState,
            "smartlink.styleAndStructure.primaryColor",
            "#00E6B2"
          ),
          secondary: get(
            currentState,
            "smartlink.styleAndStructure.secondaryColor",
            "#023365"
          ),
        },
        contact: get(currentState, "smartlink.contact", {
          ...INITIAL_STATE.merchantCustomizationInfo.contact,
        }),
      };

      dispatch(
        setMerchantCustomizationInfo({
          ...INITIAL_STATE.merchantCustomizationInfo,
          ...customization,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getInfoFirebase = (
  token: string,
  resumeType: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, undefined, IAppAction>): void => {
    const path: string = `${environment.envName}/webcheckout/notification/${resumeType}-${token}`;

    databaseRef.child(path).on("value", (snapshot) => {
      const transactionInfo: object = snapshot.val();

      if (!!transactionInfo) dispatch(setInfoFirebase(transactionInfo));
    });
  };
};
