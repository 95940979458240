import DOMPurify from "dompurify";
import React from "react";
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { Translate as T } from "react-localize-redux";
import theme from "../../../theme";
import TimerCover from "../TimerCover/TimerCover";
import { useHistory, useParams } from "react-router-dom";
import { IIndexPathParameter } from "../../../shared/infrastructure/IIndexPathParameter";
import { StructureEnum } from "../../../shared/infrastructure/StructureEnum";
import TopHeader from "../../commons/TopHeader/TopHeader";

const useStyles = makeStyles(() => ({
  buttonPadding: {
    paddingTop: "16px !important",
  },
  buyButtonStyle: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    backgroundColor: primaryColor ? primaryColor : theme.palette.info.dark,
    borderRadius: "4px",
    color: theme.palette.background.paper,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    lineHeight: "140%",
  }),
  cardPadding: {
    paddingTop: "24px !important",
  },
  descriptionTextStyle: {
    color: theme.palette.grey.A400,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "140%",
  },
  paymentResumeCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: theme.spacing(10),
    padding: "16px",
  },
  productImage: {
    borderRadius: "10px",
    height: "260px",
    maxHeight: "260px",
    maxWidth: "100%",
    objectFit: "cover",
    width: "100%",
  },
  productNameTextStyle: {
    color: theme.palette.grey.A400,
    textTransform: "uppercase",
  },
  promotionalTextStyle: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
  }),
  titlesPadding: {
    paddingTop: "8px !important",
  },
  topHeader: {
    padding: "0px 10px",
    position: "absolute",
    top: "0",
    width: "100%",
  },
  totalAmountStyle: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontFamily: "IBM Plex Sans",
  }),
  totalTextStyle: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
  }),
}));

const CenterCoverMobile: React.FC = () => {
  const smartlinkData = useSelector((state: IAppState) => state.smartlink!);
  const params = useParams<IIndexPathParameter>();
  const { merchantName, smartlinkId } = params;
  const history = useHistory();
  const totalAmount = useSelector((state: IAppState) => state.amount!.total);
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo?.colors || {}
  );
  const { primary: primaryColor } = colors;
  const classes = useStyles({ primaryColor });
  const routeChange = () => {
    history.push(
      `/${merchantName || "smartlink"}/${smartlinkId}/${StructureEnum.CHECKOUT}`
    );
  };

  const sanitizedHtml = DOMPurify.sanitize(
    smartlinkData.generalConfig.description
  );

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.topHeader}>
        <TopHeader />
      </Grid>
      <Card className={classes.paymentResumeCard}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.promotionalTextStyle}>
              {smartlinkData.generalConfig.promotionalText}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.titlesPadding}>
            <Typography variant={"h5"} className={classes.productNameTextStyle}>
              {smartlinkData.generalConfig.productName}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.cardPadding}>
            <Typography variant={"h6"} className={classes.totalTextStyle}>
              <T id="total" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"} className={classes.totalAmountStyle}>
              {Intl.NumberFormat("de-DE", {
                currency: smartlinkData.paymentConfig.amount.currency,
                minimumFractionDigits: 0,
                style: "currency",
              }).format(totalAmount)}{" "}
              {smartlinkData.paymentConfig.amount.currency}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonPadding}>
            <Button
              variant="contained"
              fullWidth
              className={classes.buyButtonStyle}
              onClick={routeChange}
            >
              {smartlinkData.generalConfig.buyButtonText}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.cardPadding}>
            <img
              src={smartlinkData.generalConfig.productImage}
              className={classes.productImage}
              alt={""}
            />
          </Grid>
          {smartlinkData.generalConfig.expirationDate &&
            smartlinkData.generalConfig.showTimer && (
              <Grid item xs={12}>
                <Box mt="24px">
                  <TimerCover />
                </Box>
              </Grid>
            )}
          <Grid item xs={12} className={classes.cardPadding}>
            <Typography className={classes.descriptionTextStyle}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizedHtml,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CenterCoverMobile;
