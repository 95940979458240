import {
  FormResponse,
  FormV1,
  FormV2,
  FormV3,
} from "../../../types/form_response";
import {
  Amount,
  CustomForm,
  GeneralConfig,
  SmartLink,
} from "../../../types/smart_link_V2";
import { defaultTo, get } from "lodash";
import { PaymentMethodsEnum } from "../infrastructure/PaymentMethodEnum";
import { PaymentTypeEnum } from "../infrastructure/PaymentTypeEnum";
import { IAmount } from "../infrastructure/IAmount";
import { CurrencyEnum } from "../infrastructure/CurrencyEnum";
import { RouteEnum } from "../infrastructure/RouteEnum";
import * as H from "history";

export const getTotalAmount = (smartLinkAmount: Amount): IAmount => {
  const taxes: number = smartLinkAmount.iva + defaultTo(smartLinkAmount.ice, 0);
  const sub_total: number =
    smartLinkAmount.subtotalIva + smartLinkAmount.subtotalIva0;

  return {
    currency: smartLinkAmount.currency as CurrencyEnum,
    smartLinkAmount,
    subTotal: sub_total,
    taxes,
    total: Math.round((taxes + sub_total) * 100) / 100,
  };
};

export const validateSmartlink = (
  generalConfig: GeneralConfig,
  history: H.History
): void => {
  const {
    executionLimit = 0,
    executionCount = 0,
    expirationDate = new Date(),
    enabled = false,
  } = generalConfig;

  if (executionLimit > 0 && executionLimit <= executionCount) {
    history.push(RouteEnum.OUTSTOCK);
  } else if (
    Number(expirationDate) < new Date().getTime() &&
    Number(expirationDate) > 0
  ) {
    history.push(RouteEnum.EXPIRED_LINK);
  } else if (!enabled) {
    history.push(RouteEnum.NOT_AVAIL);
  }
};

export const createCustomForm = (
  form: (FormV1 | FormV2 | FormV3)[]
): CustomForm[] =>
  form.map((formItem: FormV1 | FormV2 | FormV3) => ({
    disabled: formItem.disabled,
    label: get(formItem, "label", ""),
    name: get(formItem, "name", ""),
    options: formItem.options,
    placeholder: formItem.placeholder,
    required: get(formItem, "validation", "").includes("required"),
    split: false,
    type: get(formItem, "type", ""),
    value: formItem.value,
  }));

export const verifySmartlinkType = (
  smartlink: FormResponse
): PaymentTypeEnum => {
  if (
    (Array.isArray(smartlink.paymentMethod) &&
      smartlink.paymentMethod.length === 1) ||
    typeof smartlink.paymentMethod === "string"
  ) {
    const payment_method: string = Array.isArray(smartlink.paymentMethod)
      ? smartlink.paymentMethod[0]
      : smartlink.paymentMethod;

    if (payment_method === PaymentMethodsEnum.SUBSCRIPTION)
      return PaymentTypeEnum.SUBSCRIPTION;

    return PaymentTypeEnum.UNIQUE;
  }

  if (
    smartlink.paymentMethod.length > 1 &&
    smartlink.paymentMethod.includes(PaymentMethodsEnum.SUBSCRIPTION)
  )
    return PaymentTypeEnum.MIXED;

  return PaymentTypeEnum.UNIQUE;
};

export const buildSmartlinkV1 = (
  id: string,
  smartlink: FormResponse
): SmartLink => ({
  created: smartlink.created,
  formConfig: createCustomForm(smartlink.form!),
  generalConfig: {
    brandLogo: smartlink.logo,
    description: get(smartlink, "metadata.description", ""),
    enabled: true,
    executionLimit: get(smartlink, "executionLimit", 1e200),
    expirationDate: smartlink.expiration,
    productImage: get(smartlink, "metadata.image", ""),
    productName: get(smartlink, "metadata,title", ""),
    showTimer: false,
    termsAndConditions: smartlink.terms,
  },
  id: id,
  merchantName: get(smartlink, "merchantName", ""),
  paymentConfig: {
    amount: {
      currency: get(smartlink, "amount.currency", "USD"),
      ice: smartlink.amount.ice,
      iva: get(smartlink, "amount.iva", 0),
      subtotalIva: get(smartlink, "amount.subtotalIva", 0),
      subtotalIva0: get(smartlink, "amount.subtotalIva0", 0),
    },
    paymentMethod: smartlink.paymentMethod,
    paymentType: verifySmartlinkType(smartlink),
    subscriptionOptions: {
      amount: get(smartlink, "subscriptionOptions.amount"),
      periodicity: get(smartlink, "subscriptionOptions.periodicity"),
      planName: get(smartlink, "subscriptionOptions.planName", ""),
      startDate: get(smartlink, "subscriptionOptions.startDate", ""),
      terms: get(smartlink, "subscriptionOptions.terms"),
    },
  },
  publicMerchantId: smartlink.publicMerchantId,
  styleAndStructure: {
    buttonStyle: "round",
    coverModel: "left",
    primaryColor: get(smartlink, "colors.banner", "#00E6B2"),
    secondaryColor: get(smartlink, "colors.buttons", "#023365"),
    structure: "checkout",
  },
  totalAmount: smartlink.totalAmount,
  version: "v1",
});

export const orderPaymentMethods = (paymentMethods: string[]) => {
  const orderedMethods = [
    PaymentMethodsEnum.CARD,
    PaymentMethodsEnum.CARD_DYNAMIC,
    PaymentMethodsEnum.SUBSCRIPTION,
    PaymentMethodsEnum.TRANSFER,
    PaymentMethodsEnum.TRANSFER_SUBSCRIPTION,
    PaymentMethodsEnum.CARD_ASYNC,
    PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC,
    PaymentMethodsEnum.CASH,
    PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC,
  ];

  return orderedMethods.filter((method) => paymentMethods.includes(method));
};

interface SessionStorageInfo {
  merchantName: string;
  smartlinkId: string;
  dynamicForm?: string;
  formConfigString?: string;
}

export const setSessionStorageInfo = (data: SessionStorageInfo) => {
  Object.entries(data).forEach(([key, value]) =>
    sessionStorage.setItem(key, value)
  );
};

export const getSessionStorageInfo = (): SessionStorageInfo => {
  const sessionStorageInfo: any = {};

  Object.keys(sessionStorage).forEach(
    (key: string) => (sessionStorageInfo[key] = sessionStorage.getItem(key))
  );

  return sessionStorageInfo;
};
