import { useDispatch, useSelector } from "react-redux";
import { PaymentMethodsEnum } from "../../../../shared/infrastructure/PaymentMethodEnum";
import { orderPaymentMethods } from "../../../../shared/utils/utils";
import { IAppState } from "../../../../store/reducer";
import { IAmount } from "../../../../shared/infrastructure/IAmount";
import {
  setAmount,
  setCheckoutPaymentType,
  setPaymentMethods,
  setTermsAndConditions,
} from "../../../../store/actionCreators";
import * as React from "react";
import { PaymentTypeEnum } from "../../../../shared/infrastructure/PaymentTypeEnum";
import { Amount } from "../../../../../types/smart_link_V2";
import { get } from "lodash";

export interface IUseTotal {
  paymentType: PaymentTypeEnum;
  amountData: IAmount;
  paymentTypeSmarlink: string;
  handler: {
    onChange: (
      event: React.MouseEvent<HTMLElement>,
      value: PaymentTypeEnum
    ) => void;
  };
}
const useSmartlinkDetailTotal = (): IUseTotal => {
  const amountData = useSelector((state: IAppState) => state.amount!);
  const paymentType = useSelector(
    (state: IAppState) => state.checkoutPaymentType!
  );
  const smartlink = useSelector((state: IAppState) => state.smartlink);
  const termsDefaultConfiguration = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo
  );
  const dispatch = useDispatch();
  const paymentTypeSmarlink = smartlink!.paymentConfig
    .paymentType as PaymentTypeEnum;
  const paymentMethod = smartlink?.paymentConfig.paymentMethod;
  const paymentMethodsSmartlink = Array.isArray(paymentMethod)
    ? paymentMethod
    : [paymentMethod];
  const onchange = (
    event: React.MouseEvent<HTMLElement>,
    value: PaymentTypeEnum
  ) => {
    const getTotalAmount = (smartLinkAmount: Amount) => {
      const taxes: number =
        smartLinkAmount.iva +
        (smartLinkAmount.ice !== undefined ? smartLinkAmount.ice : 0);
      const sub_total: number =
        smartLinkAmount.subtotalIva + smartLinkAmount.subtotalIva0;

      return {
        currency: smartLinkAmount.currency,
        smartLinkAmount,
        subTotal: sub_total,
        taxes,
        total: taxes + sub_total,
      };
    };

    let smartLinkAmount: Amount;

    switch (value) {
      case PaymentTypeEnum.UNIQUE:
        const paymentConfig = smartlink!.paymentConfig;

        smartLinkAmount = paymentConfig.amount;

        dispatch(setAmount(getTotalAmount(smartLinkAmount)));
        dispatch(
          setTermsAndConditions(
            !!smartlink!.generalConfig.termsAndConditions!
              ? smartlink!.generalConfig.termsAndConditions!
              : get(termsDefaultConfiguration, "termsAndConditions", "")
          )
        );
        dispatch(setCheckoutPaymentType(PaymentTypeEnum.UNIQUE));
        const filteredMethods = (paymentMethodsSmartlink as string[]).filter(
          (method) =>
            !(
              method === PaymentMethodsEnum.SUBSCRIPTION ||
              method === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION ||
              method === PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC ||
              method === PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC
            )
        );

        dispatch(setPaymentMethods(orderPaymentMethods(filteredMethods)));
        break;
      case PaymentTypeEnum.SUBSCRIPTION:
        const subscriptionOptions =
          smartlink!.paymentConfig.subscriptionOptions!;
        const subscriptionMethods = (
          paymentMethodsSmartlink as string[]
        ).filter(
          (method) =>
            method === PaymentMethodsEnum.SUBSCRIPTION ||
            method === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION ||
            method === PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC ||
            method === PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC
        );

        smartLinkAmount = subscriptionOptions.amount;
        dispatch(setAmount(getTotalAmount(smartLinkAmount)));
        dispatch(
          setTermsAndConditions(
            !!subscriptionOptions.terms
              ? subscriptionOptions.terms
              : get(termsDefaultConfiguration, "termsAndConditions", "")
          )
        );
        dispatch(setCheckoutPaymentType(PaymentTypeEnum.SUBSCRIPTION));
        dispatch(setPaymentMethods(subscriptionMethods));
        break;
    }
  };

  return {
    amountData,
    handler: { onChange: onchange },
    paymentType,
    paymentTypeSmarlink,
  };
};

export { useSmartlinkDetailTotal };
