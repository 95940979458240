/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { environment } from "./environments/environment";
import { applyMiddleware, compose, createStore, Store } from "redux";
import { useReducer } from "./store/reducer";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";

const composeEnhancers = environment.devTools
  ? /* istanbul ignore next*/ // eslint-disable-next-line
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  useReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <React.StrictMode>
    <LocalizeProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </LocalizeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
