import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store/reducer";
import FormContainerMobile from "../../../FormContainerMobile/FormContainerMobile";
import FormContainer from "../../../FormContainer/FormContainer";
import { StepperEnum } from "../../../../shared/infrastructure/StepperEnum";
import { useStateApp } from "../../../../state/useStateApp";
import { PaymentTypeEnum } from "../../../../shared/infrastructure/PaymentTypeEnum";
import { CurrencyEnum } from "../../../../shared/infrastructure/CurrencyEnum";
import { defaultTo, get } from "lodash";
import { handleSmartlinkCharge } from "../state/useSmartlinkCharge";
import {
  Checkout,
  ICheckoutAmount,
  IRedirects,
} from "@kushki/react-checkout/checkout";
import { Amount } from "@kushki/react-checkout/checkout/shared/interfaces/Amount";
import { PaymentMethodsEnum } from "@kushki/react-checkout/checkout/shared/constants/PaymentMethodEnum";
import { Translate as T } from "react-localize-redux";
import { environment } from "../../../../environments/environment";
import { ErrorRouteEnum } from "../../../../shared/infrastructure/RouteEnum";
import { ICheckoutToken } from "@kushki/react-checkout/checkout/context/useCheckoutContext";
import { Box } from "@material-ui/core";

const { createContext, useContext } = React;

interface IStepperPaymentContext {
  getContent: (stepIndex: number) => JSX.Element;
  renderCheckout: (display: string) => JSX.Element;
  setStep: (stepIndex: number) => void;
  showStepper: boolean;
  step: number;
  steps: string[];
}

const StepperPaymentContext = createContext<IStepperPaymentContext>(undefined!);

export type TStepperPaymentContext = Partial<IStepperPaymentContext>;
export const StepperPaymentProvider: FC<TStepperPaymentContext> = (
  props: PropsWithChildren<TStepperPaymentContext>
) => {
  const descriptionText = document.createElement("div");

  descriptionText.innerHTML = useSelector((state: IAppState) =>
    state.termsAndConditions === "undefined"
      ? state.merchantCustomizationInfo.termsAndConditions!
      : state.termsAndConditions
  );

  const smartlink = useSelector((state: IAppState) => state.smartlink!);
  const siftScienceDetails = useSelector(
    (state: IAppState) => state.siftScienceDetails!
  );
  const amount = useSelector((state: IAppState) => state.amount!);
  const paymentMethods: string[] = useSelector(
    (state: IAppState) => state.paymentMethods
  );
  const paymentType = useSelector(
    (state: IAppState) => state.checkoutPaymentType
  );
  const termsText = descriptionText.textContent;
  const history = useHistory();
  const dynamicDetails = useSelector(
    (state: IAppState) => state.dynamicDetails
  );
  const siftDetails = useSelector(
    (state: IAppState) => state.siftScienceDetails
  );
  const smartlinkCustomizationInfo = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo
  );
  const draftMode = useSelector((state: IAppState) => state.draftMode);
  const { isMobile } = useStateApp();
  const [activeStep, setActiveStep] = React.useState(0);
  const length = Object.keys(StepperEnum).length;
  const steps = Object.keys(StepperEnum).slice(length / 2, length);
  const showStepper: boolean =
    smartlink?.siftScienceEnabled ||
    get(smartlink, "formConfig", []).length > 0;
  const [email, setEmail] = useState<string | undefined>("");

  const setStep = (stepIndex: number): void => {
    setActiveStep(stepIndex);
  };
  const getSmartlinksEmail = (): string | undefined => {
    if (dynamicDetails && dynamicDetails.email) return dynamicDetails.email;
    else if (siftScienceDetails && siftScienceDetails.email)
      return siftScienceDetails.email;
    else return;
  };

  useEffect(() => {
    setEmail(getSmartlinksEmail());
  }, [siftScienceDetails, dynamicDetails]);

  const handleCharge = useCallback(
    (checkoutToken: ICheckoutToken) => {
      handleSmartlinkCharge(
        smartlink,
        dynamicDetails!,
        siftDetails!,
        checkoutToken,
        amount,
        history
      );
    },
    [smartlink, dynamicDetails, siftDetails, amount]
  );
  const redirects: IRedirects = {
    VERIFICATION_FAILED: ErrorRouteEnum.VERIFICATION_FAILED,
  };

  let currentAmount: ICheckoutAmount;

  if (amount) {
    currentAmount = {
      amount: amount.smartLinkAmount as Amount,
      // @ts-ignore
      currency: amount.currency as CurrencyEnum,
      subTotal: amount.subTotal,
      taxes: amount.taxes,
      total: amount.total,
    };
  }

  const renderCheckout = (display: string) => {
    return (
      <T>
        {({ activeLanguage }) => (
          <Box mb={isMobile ? 6 : 6} style={{ display: display }}>
            <Checkout
              merchantId={smartlink.publicMerchantId!}
              isTestEnvironment={environment.envName !== "primary"}
              email={email}
              paymentMethods={paymentMethods! as PaymentMethodsEnum[]}
              amount={currentAmount!}
              country={smartlink.country!}
              onCheckoutTokenChange={handleCharge}
              paymentType={paymentType as PaymentTypeEnum}
              paymentTypeSmarlink={
                smartlink.paymentConfig.paymentType as PaymentTypeEnum
              }
              // @ts-ignore
              currency={smartlink.paymentConfig.amount.currency as CurrencyEnum}
              kushkiUrl={environment.kushkiUrl}
              isMobile={isMobile}
              termsAndConditions={termsText!}
              redirects={redirects}
              language={activeLanguage.code}
              redirectUrl={`${window.location.origin}/${
                smartlink?.merchantName || "smartlink"
              }/${smartlink.id}/callback`}
              redirectUrlParams={{
                brandLogo: smartlink.generalConfig.brandLogo,
                customizationInfo: smartlinkCustomizationInfo,
                dynamicDetails: defaultTo(dynamicDetails, {}),
                merchantName: smartlink.merchantName,
                productName: smartlink.generalConfig.productName,
              }}
              readOnly={draftMode}
            />
          </Box>
        )}
      </T>
    );
  };

  const getContent = (stepIndex: number): JSX.Element => {
    const stepper: StepperEnum = stepIndex;

    return (
      <>
        {isMobile ? (
          <FormContainerMobile
            display={stepper === StepperEnum.FORM ? "block" : "none"}
          />
        ) : (
          <FormContainer
            display={stepper === StepperEnum.FORM ? "block" : "none"}
          />
        )}
        {stepper === StepperEnum.PAY && renderCheckout("block")}
      </>
    );
  };

  const value: IStepperPaymentContext = {
    getContent: props.getContent || getContent,
    renderCheckout,
    setStep: props.setStep || setStep,
    showStepper,
    step: props.step || activeStep,
    steps: props.steps || steps,
  };

  return (
    <StepperPaymentContext.Provider value={value}>
      {props.children}
    </StepperPaymentContext.Provider>
  );
};

export const useStepperPaymentContext = () => {
  return useContext(StepperPaymentContext);
};
