import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import SmartlinkDetailTotal from "../SmartlinkDetailTotal/SmartlinkDetailTotal";
import { Theme } from "@material-ui/core/styles";
import DOMPurify from "dompurify";

const useStyles = makeStyles((theme: Theme) => ({
  contentCard: {
    marginBottom: "9px",
    marginTop: "0px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "8px",
  },
  descriptionText: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "140%",
  },
  detailCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    maxHeight: "700px",
    padding: "16px",
  },
  productImage: {
    borderRadius: "10px",
    height: "100%",
    maxHeight: "200px",
    maxWidth: "100%",
    objectFit: "contain",
    width: "100%",
  },
  productNameText: {
    textTransform: "uppercase",
  },
  promotionalText: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
  }),
}));

const SmartlinkDetail: React.FC = () => {
  const smartlinkData = useSelector((state: IAppState) => state.smartlink!);
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const classes = useStyles({ primaryColor });
  const [open, setOpen] = useState<boolean>(false);
  const handlerIsOpen = () => {
    setOpen(!open);
  };

  const sanitizedHtml = DOMPurify.sanitize(
    smartlinkData.generalConfig.description
  );

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Card className={classes.detailCard}>
        <CardContent className={classes.contentCard}>
          <Grid container>
            <Grid item xs={12}>
              <img
                src={smartlinkData.generalConfig.productImage}
                className={classes.productImage}
                alt={smartlinkData.generalConfig.productName}
              />
            </Grid>
            <Grid item xs={9} md={9} lg={9}>
              <Box mt={2}>
                <Typography className={classes.promotionalText}>
                  {smartlinkData.generalConfig.promotionalText}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box mt={1}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography variant="h5" className={classes.productNameText}>
                    {smartlinkData.generalConfig.productName}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SmartlinkDetailTotal
                handlerIsOpen={handlerIsOpen}
                isOpen={open}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mb={3} mt={3}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.descriptionText}>
                <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SmartlinkDetail;
